<template>
  <div class="main">
    <nyheads />
    <div class="ny-nav">
      <router-link to="/">论文助手</router-link
      >&nbsp;&nbsp;>&nbsp;&nbsp;个人中心
    </div>
    <div class="ny-con">
      <grzxnav/>
      <div class="grzx-con">
        <el-divider content-position="left">个人资料</el-divider>
        <div class="grzx-con-st">
          <div class="grzx-con-st-list">
            <div class="grzx-con-st-list-title">用户名：</div>
            <div class="grzx-con-st-list-txt">{{ userinfoinxi.username }}</div>
          </div>
          <div class="grzx-con-st-list">
            <div class="grzx-con-st-list-title">真实姓名：</div>
            <div class="grzx-con-st-list-txt">{{ userinfoinxi.realname }}</div>
          </div>
          <div class="grzx-con-st-list">
            <div class="grzx-con-st-list-title">联系方式：</div>
            <div class="grzx-con-st-list-txt">{{ userinfoinxi.usertel }}</div>
          </div>
          <div class="grzx-con-st-list">
            <div class="grzx-con-st-list-title">联系邮箱：</div>
            <div class="grzx-con-st-list-txt">{{ userinfoinxi.cemail }}</div>
          </div>
          <div class="grzx-con-st-list">
            <div class="grzx-con-st-list-title">联系地址：</div>
            <div class="grzx-con-st-list-txt">{{ userinfoinxi.caddress }}</div>
          </div>
        </div>
        <div class="gezx-nr-cz">
          <el-button type="primary" @click="dialogTableVisible = true"
            >修改个人信息</el-button
          >
          <el-button type="warning" @click="dialogTableVisiblepsw = true"
            >修改密码</el-button
          >
        </div>
      </div>
    </div>
    <footers />

    <!-- 修改资料弹窗 -->
    <el-dialog v-model="dialogTableVisible" title="修改个人信息" width="800">
      <el-form>
        <el-form-item label="真实姓名">
          <el-input v-model="userinfo.realname" placeholder="请输入真实姓名" />
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="userinfo.usertel" placeholder="请输入联系方式" />
        </el-form-item>
        <el-form-item label="联系邮箱">
          <el-input v-model="userinfo.cemail" placeholder="请输入联系邮箱" />
        </el-form-item>
        <el-form-item label="联系地址">
          <el-input v-model="userinfo.caddress" placeholder="请输入联系地址" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
          <el-button @click="dialogTableVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 修改密码弹窗 -->
    <el-dialog v-model="dialogTableVisiblepsw" title="修改密码" width="800">
      <el-form>
        <el-form-item label="旧密码">
          <el-input
            v-model="psw.oldpsw"
            type="password"
            placeholder="请输入当前密码"
          />
        </el-form-item>
        <el-form-item label="新密码">
          <el-input
            type="password"
            v-model="psw.newpsw"
            show-password
            placeholder="请设置新密码"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmitpsw">保存</el-button>
          <el-button @click="dialogTableVisiblepsw = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from "@element-plus/icons-vue";

const router = useRouter();

let dialogTableVisible = ref(false);
let dialogTableVisiblepsw = ref(false);
let psw = reactive({
  oldpsw: "",
  newpsw: "",
});
let userinfo = reactive({
  realname: "",
  usertel: "",
  cemail: "",
  caddress: "",
});
let userinfoinxi = reactive({});

// 获取用户信息
const getuserinfo = () => {
  get("/api/User/GetTheUserInfo").then((result) => {
    Object.assign(userinfoinxi, result.data);
    Object.assign(userinfo, result.data);
  });
};
getuserinfo();

// 修改个人信息
const onSubmit = () => {
  post("/api/User/UpdateUserInfo", userinfo).then((res) => {
    ElMessage({
      message: "保存成功！",
      duration: 1000,
      onClose: () => {
        getuserinfo();
        dialogTableVisible.value = false;
      },
    });
  });
};
// 修改密码
const onSubmitpsw = () => {
  post("/api/User/UpdateUserPass", {
    oldpass: psw.oldpsw,
    newpass: psw.newpsw,
    surepass: psw.newpsw,
  }).then((res) => {
    ElMessage({
      message: "修改成功！",
      duration: 1000,
      onClose: () => {
        dialogTableVisiblepsw.value = false;
      },
    });
  });
};


// 写作记录
const clickxzjl = () => {
  router.push({
    path: "/xzjl",
    query: {},
  });
};

// 我的信息
const clickwdxx = () => {
  router.push({
    path: "/grzx",
    query: {},
  });
};

// 注销
const loginout = () => {
  ElMessage({
    message: "已退出系统！",
    duration: 1000,
    onClose: () => {
      // 清除token
      window.localStorage.removeItem("token");
      // 清除个人信息
      window.localStorage.removeItem("username");
      window.localStorage.removeItem("userInfo");
      // 清除url
      window.localStorage.removeItem("loginurl");
      router.push({
        path: "/",
        query: {},
      });
    },
  });
};
</script>

<style scoped>
:deep(.el-menu) {
  border-right: 0;
}
:deep(.el-divider__text) {
  font-weight: bold;
  font-size: 18px;
}
.main {
  background: #ebedf3;
}
.ny-nav {
  width: 1200px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888;
}
.ny-nav a:link,
.ny-nav a:visited {
  color: #888;
  text-decoration: none;
}
.ny-nav a:hover {
  color: #2489f2;
}
.ny-con {
  width: 1200px;
  height: auto;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  border: 1px solid #dddfe5;
  border-radius: 2px;
  padding: 30px;
  margin: auto;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.ny-con:after {
  content: "";
  display: table;
  clear: both;
}
.grzx-nav {
  float: left;
  width: 240px;
  min-height: 600px;
  padding-right: 30px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
}
.grzx-con {
  float: right;
  width: calc(100% - 240px);
  height: 600px;
  box-sizing: border-box;
  padding-left: 30px;
}

.grzx-nav-tx {
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.grzx-nav-yhm {
  width: calc(100% - 20px);
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.grzx-nav-st {
  padding: 20px 0;
}
.grzx-nav-yhm-lever {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 5px 0;
  background: url("/img/gerenzhongxin/VIP.png");
}
.grzx-nav-yhm-lever-num {
  position: absolute;
  left: 15px;
  top: 15px;
  color: #fff;
  font-weight: bold;
}
.grzx-con-st {
  padding: 10px 0;
}
.grzx-con-st-list {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}
.grzx-con-st-list-title {
  width: 100px;
}
.grzx-con-st-list-txt {
  width: calc(100% - 100px);
}
.gezx-nr-cz {
  margin-top: 20px;
}
</style>
